<template>
  <div class="bg-b-lue" v-on:click.self="redirectToHomePage()">
    <div class="frame-35628">
      <PaymentFailure id="completed-animation" />
      <div class="company-name-wrapper" style="margin: 1rem 0">
        {{ errorMessage }}
      </div>
      <div class="buttons-wrapper">
        <div
          class="button1"
          style="
            background-color: white;
            border: 2px solid #11365a;
            padding: 14px 0px;
          "
          v-on:click.prevent="redirectToHomePage()"
        >
          <div class="button-text" style="color: #11365a">Close</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentFailure from "@/assets/Animation/PaymentFailure/PaymentFailure.vue";

export default {
  name: "CreditPlanMapFailure",
  components: {
    PaymentFailure,
  },
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  async mounted() {},
  methods: {
    redirectToHomePage() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30vw;
  position: absolute;
  background: #ffffff;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.button-text {
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  cursor: pointer;
}
.company-name-wrapper {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.button1 {
  border-radius: 13px !important;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 150px;
  width: 100%;
  cursor: pointer;
}
.button-text {
  color: #ffffff;
  text-align: center;
  font-family: "NunitoSans-SemiBold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 75%;
    padding: 1rem 2rem;
  }
  #completed-animation {
    width: 75px;
    height: 75px;
  }
  .company-name-wrapper {
    font-size: 16px !important;
  }
  .button1 {
    width: 100% !important;
    padding: 0 !important;
    height: 40px !important;
  }
  .button-text {
    font-size: 14px;
    width: 100%;
  }
  .active-order {
    border-radius: 13px !important;
  }
  .button1 div {
    padding: 0 !important;
  }
}
</style>
